<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="userData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="4">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Utilisateur
            </h6>
          </b-col>

          <b-col cols="8">
            <b-button
              v-if="$router.name == 'user-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'user-view',
                  params: { id: userData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'user-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'user-view',
                  params: { id: userData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'user-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des Utilisateurs</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col cols="12" xl="12" md="12">
            <b-card no-body class="invoice-preview-card">
              <b-row class=""> 

                <b-col cols="12" md="6">
                  <!-- role -->
                  <validation-provider
                    #default="validationContext"
                    name="role"
                    rules="required"
                  >
                    <b-form-group
                      label="Sélectionner Rôle de l'Utilisateur"
                      label-for="role"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="userData.role"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="userOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="role"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        Sélectionner Role dans la liste
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="">
                <!-- fullName -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="validationContext"
                    name="fullName"
                    rules="required"
                  >
                    <b-form-group
                      label="Nom de l'utilisateur"
                      label-for="fullName"
                    >
                      <b-form-input
                        id="fullName"
                        v-model="userData.fullName"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: admin@cbpcmmunity.com"
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer nom / prenom de l'utilisateur s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                
                <!-- email -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="validationContext"
                    name="email"
                    rules="required"
                  >
                    <b-form-group
                      label="Email de l'utilisateur"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="userData.email"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: admin@cbpcmmunity.com"
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer l'email de l'utilisateur s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- username -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="validationContext"
                    name="username"
                    rules="required"
                  >
                    <b-form-group
                      label="Compte d'utilisateur"
                      label-for="username"
                    >
                      <b-form-input
                        id="username"
                        v-model="userData.username"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Compte1,..."
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer le compte d'utilisateur s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- pass -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="validationContext"
                    name="password"
                    rules="required"
                  >
                    <b-form-group label="Mot de passe" label-for="password">
                      <b-form-input
                        id="password"
                        v-model="userData.password"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Secret001,..."
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer Mot de passe s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- pass -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="validationContext"
                    name="contact"
                    rules="required"
                  >
                    <b-form-group label="N° Téléphone" label-for="contact">
                      <b-form-input
                        id="contact"
                        v-model="userData.contact"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: 099999999"
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer N° Téléphone s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="userData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// sweet alert
import { codeBasic } from "../../extensions/sweet-alert/code";
// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"; 
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BMedia,
  BAvatar,
  BTable,
  BCardHeader,
  BCardTitle,

  // Form Validation
  BFormInvalidFeedback,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "user";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,

    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      branchOptions: [],
      agentOptions: [],
      userOptions: [],
    };
  },
  props: {
    userData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    // console
    if (router.currentRoute.name == "user-add-new") {
      this.operationTitle = "Nouveau ";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "user-view") {
      this.operationTitle = "Détails du ";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "user-edit") {
      this.operationTitle = "Modification du ";
      this.operationIcon = "EditIcon";
    }

    if (router.currentRoute.name == moduleName + "-add-new") {
      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;

      //
      // empty values
      // this.$props.userData.name = "";
      // this.$props.userData.permissions = [];
      // //
      // this.$props.userData.users.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
      //
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == moduleName + "-edit") {
      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }

    // load agents list
    await myApi
      .get(`agent`)
      .then((response) => {
        // default action
        var data = response.data.data;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.agentOptions.push({
            label:
              data[i].firstname +
              " " +
              data[i].lastname +
              " " +
              data[i].surname,
            value: data[i].sku_agent,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });

    // branchOptions
    await axios
      .get(`branch`)
      .then((response) => {
        // default action
        this.branchOptions.push({
          label: "Tout",
          value: "*",
        });

        var data = response.data.data;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          // value: data[i].sku_branch,
          this.branchOptions.push({
            label: data[i].name,
            value: data[i].name,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // userOptions
    // load user list
    await axios
      .get(`role`)
      .then((response) => {
        // default action
        var data = response.data.data;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.userOptions.push({
            label: data[i].name,
            value: data[i].name,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    reinitForm() {
      this.$props.userData.name = "";
    },
    changeAgent(event) {
      this.userData.fullname =
        event.target.options[event.target.options.selectedIndex].text;
      /* let user = _.split(event.target.value, "-", 2); //using lodash here. You can also just use js split func
      let id = user[0]; // your id
      let name = user[1]; // your name */
    },
    formReadyForSubmission() {
      // validation check condition
      this.errors = [];
      if (this.$props.userData.name == "") {
        this.errors.push("Compléter le nom du user svp.");
      }
      if (this.errors.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    async addUpdateItemAction() {
      // ---------------------------
      let formData = new FormData();
      // ---------------------------
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "user-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "user-view") {
        method = "get";
      }
      if (router.currentRoute.name == "user-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.userData.id;
      }

      // finalise form data
      formData = this.$props.userData;

      myApi({
        url: "user" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;

          if (response.status == 200 || response.status == 201) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "user-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "BellIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          this.actionName = defaultActionText;
          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "AlertTriangleIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "user-view" });
    },
    goToNextItem() {
      router.push({ name: "user-list" });
    },
    goToList() {
      router.push({ name: "user-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetmoduleData = () => {
      this.$props.userData = this.$props.moduleData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetmoduleData);
    //
    // let itemsList = ref([]);
    // store.dispatch("app-user/fetchItems").then((response) => {
    //   itemsList.value = response.data;
    //   // itemsList = response.data;
    // });

    // fill items
    // let itemsOptions = itemsList;

    let typeOptions = [
      { label: "Matière Première", value: "first-way" },
      { label: "Produit Semi-Fini", value: "half-finished" },
      { label: "Produit Fini", value: "finished" },
      { label: "Produit Dérivé", value: "derived" },
      { label: "Consommables", value: "consummable" },
      { label: "Service", value: "service" },
      { label: "Autres", value: "other" },
    ];

    const userOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const paymentMethods = ["Bank Account", "PayPal", "UPI Transfer"];

    return {
      // itemsList,
      // itemsOptions,
      paymentMethods,
      refFormObserver,
      getValidationState,
      resetForm,
      // permissionsData,
      typeOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
